import {AllowedMimeType, FileTypesEnum} from "@ncnp-mono/generics";

export const ComplementDossierMimeTypes: AllowedMimeType[] = [
    {
        type: FileTypesEnum.JPEG,
        extension: 'jpg',
    },
    {
        type: FileTypesEnum.PNG,
        extension: 'png',
    },
    {
        type: FileTypesEnum.PDF,
        extension: 'pdf',
    },
    {
        type: FileTypesEnum.TXT,
        extension: 'txt',
    },
    {
        type: FileTypesEnum.DOC,
        extension: 'doc',
    },
    {
        type: FileTypesEnum.DOCX,
        extension: 'docx',
    },
    {
        type: FileTypesEnum.XLS,
        extension: 'xls',
    },
    {
        type: FileTypesEnum.XLSX,
        extension: 'xlsx',
    }
];
