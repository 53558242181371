import Vue from 'vue';
import Component from 'vue-class-component';
import { ContactService, getImage } from '@ncnp-mono/utils';
import { Inject } from 'vue-property-decorator';
import html from './contact-information.component.html';
import { ContactTaxOfficeView } from '@ncnp-mono/generics';

@Component({
    template: html,
    methods: {
        getImage,
    },
})
export class ContactInformationComponent extends Vue {

    @Inject()
    private contactService?: ContactService;

    private taxOfficeContacts: ContactTaxOfficeView[] = [];

    mounted() {
        this.getCreditorContacts().then();
    }

    private async getCreditorContacts(): Promise<void> {
        this.taxOfficeContacts = await this.contactService?.getContacts() || [];
    }

    private canDrawDivider(index: number): boolean {
        return index !== (Object.keys(this.taxOfficeContacts).length - 1);
    }

    private closeTab(): void {
        window.close();
    }
}
