import Vue from "vue";
import Component from "vue-class-component";
import html from "./organisation-general-data.component.html";
import {Inject} from "vue-property-decorator";
import {OrganisationService} from "@ncnp-mono/utils";
import {Organisation} from "@ncnp-mono/generics";

@Component({
    template: html,
})
export class OrganisationGeneralDataComponent extends Vue {

    @Inject()
    private organisationService!: OrganisationService;

    public organisation: Organisation | null = null;

    beforeMount(): void {
        this.getOrganisation();
    }

    private async getOrganisation(): Promise<void> {
        this.organisation = await this.organisationService.getOrganisationForUser();
    }
}
