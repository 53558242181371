import Vue from 'vue';
import Component from 'vue-class-component';
import html from './bulk-download-tax-reports.component.html';
import {Inject} from "vue-property-decorator";
import {BulkTaxReportsService, formatDate, getImage, TaxOfficeService} from "@ncnp-mono/utils";
import {TaxOffice, TaxReportsArchiveFile} from "@ncnp-mono/generics";
import { compareAsc } from 'date-fns';
import {CreateTaxReportsDialog} from "../../../dossier/dialogs/create-tax-reports/create-tax-reports.dialog";

@Component({
    template: html,
    methods: {
        getImage
    },
    filters: {
        date: formatDate,
    },
})
export class BulkDownloadTaxReportsComponent extends Vue {

    @Inject()
    private bulkTaxReportsService!: BulkTaxReportsService;

    @Inject()
    private taxOfficeService!: TaxOfficeService;

    public taxOffices: TaxOffice[] | null = null;
    public file: TaxReportsArchiveFile | null = null
    public fileDownloading = false;
    public loading = false;
    public downloadStarted = false;

    public beforeMount(): void {
        this.getFile();
    }

    public getFile(): void {
        this.loading = true;

        this.bulkTaxReportsService.getArchiveFile(this.$route.params.fileId)
            .then(file => this.file = file)
            .catch(() => this.$snackbar.danger('Er is een fout opgetreden bij het laden van het bestand'))
            .finally(() => this.loading = false);
    }

    public async downloadFile(file: TaxReportsArchiveFile): Promise<void> {
        this.fileDownloading = true;
        try {
            this.downloadStarted = true;
            await this.bulkTaxReportsService.downloadArchiveFile(file);
        } catch {
            this.fileDownloading = false;

            this.$snackbar.danger('Downloaden zip-bestand mislukt');
        }
    }

    public isExpired(file: TaxReportsArchiveFile): boolean
    {
        const currentDate = new Date();
        const dateOnly = new Date(
            currentDate.getFullYear(),
            currentDate.getMonth(),
            currentDate.getDate(),
            0, 0, 0, 0
        );
        return compareAsc(dateOnly, file.expiresAt) >= 0;
    }

    public async openCreateTaxReportsDialog(): Promise<void> {
        try {
            const taxOffices = this.taxOffices ?? await this.taxOfficeService.getTaxOffices();

            this.$vueDialogs.open(CreateTaxReportsDialog, {
                propsData: {
                    taxOffices: taxOffices
                },
                dialogSettings: {
                    maxWidth: "800px"
                }
            });
        } catch {
            this.$snackbar.danger('Door een onbekende fout kunt u momenteel geen aanvraag indienen');
        }
    }
}
