import { DataTableHeader } from 'vuetify';

export const BulkUploadHeaders: DataTableHeader[] = [
    {
        text: 'Subjectnummer',
        value: 'aggregatedData.subjectnummer',
        sortable: false,
    },
    {
        text: 'Postcode',
        value: 'aggregatedData.postcode',
        sortable: false,
    },
    {
        text: 'Huisnr',
        value: 'aggregatedData.huisnummer',
        sortable: false,
    },
    {
        text: 'Letter',
        value: 'aggregatedData.huisletter',
        sortable: false,
    },
    {
        text: 'Toevoeging',
        value: 'aggregatedData.adrestoevoeging',
        sortable: false,
    },
    {
        text: 'Vorderingsnummer',
        value: 'aggregatedData.vorderingsnummer',
        sortable: false,
    },
    {
        text: 'Wozobjectnummer',
        value: 'aggregatedData.wozobjectnummer',
        sortable: false,
    },
    {
        text: 'Type',
        value: 'type',
        sortable: false,
    },
    {
        text: 'Reden/Soort',
        value: 'reason-or-kind',
        width: '180px',
        sortable: false,
    },
    {
        text: 'Bestanden',
        value: 'bestanden',
        sortable: false,
    },
    {
        text: 'Actie',
        value: 'actions',
        sortable: false,
    },
];
