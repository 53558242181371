import {IsDialogMixin} from "@bakerware/vue-dialogs";
import Component from "vue-class-component";
import html from './complement-dossier.dialog.html';
import {Observable, type VForm} from "@bakerware/vue-utils";
import {Inject, Prop, Ref} from "vue-property-decorator";
import type {Dossier, DossierFileUploadRequest} from "@ncnp-mono/generics";
import {DossierService, formatAddress, formatDate, getImage} from "@ncnp-mono/utils";
import {FileUploadComponent} from "@ncnp-mono/components";
import {hasNoScriptTags} from "@bakerware/vue-validation-rules";
import {ComplementDossierMimeTypes} from "../../config";
import { UnprocessableEntityException } from '@bakerware/http';

@Component({
    template: html,
    components: {
        FileUploadComponent
    },
    methods: {
        formatAddress,
        getImage
    },
    filters: {
        date: formatDate,
    },
})
export class ComplementDossierDialog extends IsDialogMixin {

    @Prop()
    dossier!: Dossier;

    @Inject()
    dossierService!: DossierService;

    @Ref()
    public form!: VForm;

    public dossierFileUploadRequest: DossierFileUploadRequest = {
        files: []
    };
    public result = new Observable<boolean>();
    public rules = {
        comment: [hasNoScriptTags,],
    };

    public allowedMimeTypes = ComplementDossierMimeTypes;

    public async submit(): Promise<void> {
        if (!this.form.validate()) {
            return;
        }

        try {
            await this.dossierService.uploadDossierFile(this.dossier.id, this.dossierFileUploadRequest);

            this.$snackbar.success('Het bezwaar is succesvol aangevuld');

            this.result.emit(true);

            this.activeDialog.close();
        } catch (e) {
            if (e instanceof UnprocessableEntityException) {
                const errors = e.errors;
                for (const error in errors) {
                    this.$snackbar.danger(`${errors[error]}`);
                }
                return;
            }

            this.$snackbar.danger(
                `Er is een fout opgetreden bij het aanvullen van het bezwaar`
            );
        }
    }
}
