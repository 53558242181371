import Vue from "vue";
import Component from 'vue-class-component';
import html from './dossiers-overview.component.html';
import {Inject, Watch} from "vue-property-decorator";
import {DataOptions, DataTableHeader} from "vuetify";
import { ChangesOrder, Claim, Dossier, FiltersDossiers, PaginatesResults, TaxOffice } from "@ncnp-mono/generics";
import {formatAddress, formatDate, OrganisationDossierService, TaxOfficeService} from "@ncnp-mono/utils";
import {DossiersOverviewHeaders, PaginationFooterSettings} from "../../config";
import {DatePicker} from '@bakerware/vuetify-date-picker';
import {ComplementDossierDialog} from "../../dialogs/complement-dossier/complement-dossier.dialog";
import {RevokeClaimDialog} from "../../dialogs/revoke-claim/revoke-claim.dialog";
import {CreateTaxReportsDialog} from "../../dialogs/create-tax-reports/create-tax-reports.dialog";

@Component({
    template: html,
    filters: {
        date: formatDate,
    },
    components: {
        DatePicker,
    },
    methods: {
        formatAddress,
    },
})

export class DossiersOverviewComponent extends Vue {

    @Inject()
    private organisationDossierService!: OrganisationDossierService;

    @Inject()
    private taxOfficeService!: TaxOfficeService;

    @Watch('searchTerm')
    onSearchTermChange(): void {
        clearTimeout(this.timerId);
        this.timerId = window.setTimeout(this.getDossiers, 300);
    }

    @Watch('filter', {deep: true})
    @Watch('options', {deep: true})
    onFilterChange = () => this.getDossiers();

    public loading = true;
    public loadingText = 'De bezwaren worden opgehaald. Een ogenblik geduld.';
    public timerId?: number;
    public searchTerm = '';

    public filter: FiltersDossiers = {
        taxOfficeId: null,
        showAuthorized: true,
        showNotAuthorized: false,
        startDate: null,
        endDate: null,
    };

    public headers: DataTableHeader[] = DossiersOverviewHeaders;
    public footerSettings = PaginationFooterSettings;
    public options: DataOptions | null = null;
    public datePickerOptions = {
        filled: false,
        flat: true,
        dense: false,
        hideDetails: true,
    };

    public taxOffices: TaxOffice[] = [];
    public dossiers: Dossier[] = [];
    public totalDossiers = 0;

    beforeMount(): void {
        this.getTaxOffices();
    }

    private taxOfficeInactive(dossier: Dossier): boolean {
        return !dossier.taxOffice.active;
    }

    private hasInactiveClaims(dossier: Dossier): boolean {
        return dossier.claims.filter((claim) => this.isInactiveClaim(claim)).length !== 0;
    }

    public openCreateTaxReportsDialog(): void {
        this.$vueDialogs.open(CreateTaxReportsDialog, {
            propsData: {
                taxOffices: this.taxOffices
            },
            dialogSettings: {
                maxWidth: "800px"
            }
        });
    }

    public openComplementDossierDialog(dossier: Dossier): void {
        this.$vueDialogs.open(ComplementDossierDialog, {
            propsData: {
                dossier: dossier,
            },
            dialogSettings: {
                maxWidth: '728px',
            },
        }).result.observe(this.getDossiers);
    }

    private async getDossiers(): Promise<void> {
        this.loading = true;

        const paginatorRequest: PaginatesResults & ChangesOrder & FiltersDossiers = {
            ...this.filter,
            searchTerm: this.searchTerm,
            page: this.options?.page ?? 1,
            limit: this.options?.itemsPerPage ?? 10,
            orderBy: this.options?.sortBy[0],
            orderDirection: this.options?.sortDesc[0] ? 'DESC' : 'ASC',
        };

        this.organisationDossierService.getOrganisationDossiers(paginatorRequest)
            .then((response) => {
                this.dossiers = response.data;
                this.totalDossiers = response.meta.total;
            })
            .catch(() => {
                this.$snackbar.danger('Het laden van de bezwaren is niet gelukt');
            })
            .finally(() => this.loading = false);
    }

    private getTaxOffices(): void {
        this.taxOfficeService.getTaxOffices().then(response => this.taxOffices = response);
    }

    private isInactiveClaim(claim: Claim): boolean {
        return claim.revokedAt !== null || claim.reasonNotAuthorized !== null;
    }

    private viewClickedRow(dossier: Dossier): void {
        this.$router.push({
            name: 'dossier-details',
            params: {
                dossierId: dossier.id.toString(),
            },
        });
    }

    private canRevokeClaim(claims: Claim[], taxOfficeIsActive: boolean): boolean {
        return claims.filter((claim) => taxOfficeIsActive && this.isActiveClaim(claim)).length === 1;
    }

    private isActiveClaim(claim: Claim): boolean {
        return !claim.revokedAt && !claim.reasonNotAuthorized;
    }

    private revokeClaim(claims: Claim[]): void {
        const claim = claims.find((claim) => this.isActiveClaim(claim));
        this.$vueDialogs.open(RevokeClaimDialog, {
            propsData: {
                claim: claim
            },
            dialogSettings: {
                maxWidth: '728px',
            },
        }).result.observe(this.getDossiers);
    }
}
