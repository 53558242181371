import {IsDialogMixin} from "@bakerware/vue-dialogs";
import Component from "vue-class-component";
import html from './create-dossier.dialog.html';
import {Observable} from "@bakerware/vue-utils";
import {Inject, Prop} from "vue-property-decorator";
import {DossierService, getImage} from "@ncnp-mono/utils";
import type {DossierRequest, TaxOffice} from "@ncnp-mono/generics";
import { ClaimReasonDescriptionsEnum } from "@ncnp-mono/generics";

@Component({
    template: html,
    methods: {
        getImage
    }
})
export class CreateDossierDialog extends IsDialogMixin {

    @Inject()
    dossierService!: DossierService;

    @Prop()
    taxOffice!: Partial<TaxOffice>;

    @Prop()
    dossierRequest!: DossierRequest;

    public claimReasonDescriptionsEnum = ClaimReasonDescriptionsEnum;

    public loading = false;

    public result = new Observable<string>();

    public async confirm(): Promise<void> {
        try {
            this.loading = true;

            const response = await this.dossierService.createDossier(this.dossierRequest);
            this.result.emit(response.dossierId);
            this.activeDialog.close();
        } catch (e) {
            this.$snackbar.danger('Fout bij aanmaken bezwaar');
        } finally {
            this.loading = false;
        }
    }
}
