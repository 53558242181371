import Vue from "vue";
import Component from "vue-class-component";
import html from "./dashboard-overview.component.html";
import { Inject } from "vue-property-decorator";
import { TaxOffice } from "@ncnp-mono/generics";
import { getImage, TaxOfficeService } from "@ncnp-mono/utils";

@Component({
    template: html,
    methods: {
        getImage
    }
})
export class DashboardOverviewComponent extends Vue {

    @Inject()
    private taxOfficeService!: TaxOfficeService;

    public taxOffices: TaxOffice[] = [];

    private mounted(): void {
        this.getTaxOffices();
    }

    private getTaxOffices(): void {
        this.taxOfficeService.getTaxOffices().then(result => this.taxOffices = result);
    }
}
