import { ContactInformationComponent } from './pages/contact-information/contact-information.component';

export const ContactInfoRoutingModule = [
    {
        path: '/contact-informatie',
        name: 'contact-info',
        component: ContactInformationComponent,
        meta: {
            requiresAuth: false,
        },
    },
];
