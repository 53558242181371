import { DossierService, FileService, TaxOfficeService, BulkBatchService, formatDate } from "@ncnp-mono/utils";
import type { VForm } from "@bakerware/vue-utils";
import { hasNoScriptTags, max, min, numeric, postcode, required } from "@bakerware/vue-validation-rules";
import { ConfirmDialog, FileUploadComponent } from "@ncnp-mono/components";
import {
    BulkBatch,
    ClaimReasonCodesEnum,
    CsvValidationResponse,
    DossierRequest,
    ReasonObjection,
    TaxOffice
} from "@ncnp-mono/generics";
import { filesize } from "filesize";
import Vue from "vue";
import Component from "vue-class-component";
import { Inject, Ref, Watch } from "vue-property-decorator";
import { CreateDossierMimeTypes } from "../../config";
import { CreateBulkUploadMimeTypes } from "../../config/bulk-upload-mime-type";
import { CreateBulkDialog } from "../../dialogs/create-bulk/create-bulk.dialog";
import { CreateDossierRedirectDialog } from "../../dialogs/create-dossier-redirect/create-dossier-redirect.dialog";
import { CreateDossierDialog } from "../../dialogs/create-dossier/create-dossier.dialog";
import html from "./create-dossier.component.html";

@Component({
    components: {
        FileUploadComponent
    },
    template: html,
    filters: {
        date: formatDate,
    },
})
export class CreateDossierComponent extends Vue {
    @Inject()
    bulkBatchService!: BulkBatchService;

    @Inject()
    fileService!: FileService;

    @Inject()
    dossierService!: DossierService;

    @Inject()
    taxOfficeService!: TaxOfficeService;

    @Ref()
    public form!: VForm;

    @Ref()
    public claimNumber!: HTMLFormElement;

    @Ref()
    public objectNumber!: HTMLFormElement;

    @Watch('dossierRequest.claimNumber')
    @Watch('dossierRequest.claims')
    onClaimDataChange(): void {
        this.claimNumber.validate();
        this.objectNumber.validate();
    }

    public bulkBatch: BulkBatch[] = [];

    public selectedUploadTypes: string | null = null;
    public uploadTypes = [
        { text: 'Nieuw', value: 'Nieuw' },
        { text: 'Aanvulling', value: 'Aanvulling' },
        { text: 'Intrekking', value: 'Intrekking' },
    ];

    public activeTab = 0;
    public taxOffices: TaxOffice[] = [];
    public dossierRequest: DossierRequest = {
        taxOfficeCode: this.$route.params.taxOfficeCode || "",
        claims: [
            {
                reason: ""
            }
        ],
        files: []
    };

    public objectionReasons: ReasonObjection[] = [];

    public rules = {
        taxOfficeCode: [required],
        name: [hasNoScriptTags],
        claimNumber: [numeric, (v: number) => max(v, 15), this.customRequired],
        subjectNumber: [numeric, (v: number) => max(v, 10)],
        dossierCharacteristic: [hasNoScriptTags],
        zipcode: [postcode],
        city: [hasNoScriptTags],
        street: [hasNoScriptTags],
        number: [numeric],
        numberLetter: [hasNoScriptTags],
        numberSuffix: [hasNoScriptTags],
        objectNumber: [hasNoScriptTags, (v: number) => max(v, 12), this.customRequired],
        wozObjectNumber: [numeric, (v: number) => min(v, 12), (v: number) => max(v, 12), this.customRequired],
        reason: [required],
        uploadTypes: [required]
    };

    public allowedMimeTypes = CreateDossierMimeTypes;
    public allowedMimeTypeForBulkUpload = CreateBulkUploadMimeTypes;

    public uploadedCsv = false;
    public revalidateCsv = false;
    public csvValidationResponse: CsvValidationResponse = {};

    public redirectBack = false;
    
    public hasUnfinishedBulkUploads = false
    public id: string|null = null

    public uploadLimit = 524; // Is 500MB
    public uploadLimitReached = false;

    async mounted(): Promise<void> {
        this.taxOfficeService.getTaxOffices()
            .then(result => this.taxOffices = result)
            .then(() => {
                if (this.$route.params.taxOfficeCode) {
                    this.getObjectionReasonsForTaxOffice();
                }
            });

        this.bulkBatchService.getBulkBatches()
            .then(result => {
                this.bulkBatch = result;
                if (result.length > 0) {
                    this.hasUnfinishedBulkUploads = true;
                }
            });
    }

    public replaceFile(): void {
        this.$snackbar.success("Bestand(en) succesvol vervangen");
    }

    public get isBulkUpload(): boolean {
        return this.activeTab === 1;
    }

    public get title(): string {
        return this.isBulkUpload ? 'Bulk upload' : 'Gegevens vordering';
    }

    public get canSubmit(): boolean {
        return this.isBulkUpload && (
            this.selectedUploadTypes !== null
            && this.dossierRequest.taxOfficeCode !== ''
            && 0 in this.dossierRequest.files
        );
    }

    public get isWozClaim(): boolean {
        const claim = this.dossierRequest.claims[0];

        return claim.reason === ClaimReasonCodesEnum.WOZ_TE_HOOG || claim.reason === ClaimReasonCodesEnum.WOZ_TE_LAAG;
    }

    public resetUpload(): void {
        this.uploadedCsv = false;
        this.csvValidationResponse = {};
    }

    public async onTabChange(): Promise<void> {
        if (!this.isBulkUpload && this.dossierRequest.taxOfficeCode) {
            await this.getObjectionReasonsForTaxOffice();
        }
        this.resetUpload();
        this.dossierRequest.files = [];
        this.form.resetValidation();
    }

    public allowedMimeTypeFor() {
        if (this.isBulkUpload) {
            return this.allowedMimeTypeForBulkUpload;
        }
        return this.allowedMimeTypes
    }

    public async submit(): Promise<void> {
        if (!this.form.validate()) {
            return;
        }

        const taxOffice = this.taxOffices.find(taxOffice => taxOffice.code === this.dossierRequest.taxOfficeCode) as TaxOffice;

        const dialogSettings = {
            propsData: {
                taxOffice: {
                    name: taxOffice.name,
                    logo: taxOffice.logo,
                    mimeType: taxOffice.mimeType
                },
                dossierRequest: this.dossierRequest
            },
            dialogSettings: {
                scrollable: true,
                maxWidth: "728px"
            }
        };

        this.isBulkUpload
            ? this.$vueDialogs.open(CreateBulkDialog, dialogSettings).result.observe(this.createdBulkDossier)
            : this.$vueDialogs.open(CreateDossierDialog, dialogSettings).result.observe(this.createdSingleDossier);
    }

    public async submitBulkUpload(): Promise<void> {
        if (!this.form.validate()) {
            return;
        }

        const fileSize = filesize(this.dossierRequest.files[0].size, { exponent: 2, output: 'object' });

        if (this.dossierRequest.files.length > 0 && parseInt(fileSize.value) > this.uploadLimit) {
            this.uploadLimitReached = true;
            return;
        }

        const taxOffice = this.taxOffices.find(taxOffice => taxOffice.code === this.dossierRequest.taxOfficeCode) as TaxOffice;

        const formData = new FormData();
        formData.append("bulkHandler", 'BulkUploadBatchHandler');
        formData.append("type", this.selectedUploadTypes ?? '');
        formData.append("file", this.dossierRequest.files[0]);
        formData.append('taxOfficeId', taxOffice.id.toString());

        const dialogSettings = {
            propsData: {
                taxOffice: taxOffice,
                BulkUploadRequest: formData,
                info: {
                    type: this.selectedUploadTypes ?? '',
                    file: this.dossierRequest.files[0]
                },
            },
            dialogSettings: {
                scrollable: true,
                maxWidth: "728px"
            }
        };

        this.$vueDialogs.open(CreateBulkDialog, dialogSettings)
            .result.observe((bulkBatch: BulkBatch) =>
                 this.createdBulkDossier(bulkBatch)
            )
    }

    private customRequired(): boolean | string {
        if (
            !this.isBulkUpload
            && !this.dossierRequest.claimNumber
            && !this.dossierRequest.claims[0].objectNumber
        ) {
            return this.isWozClaim ? 'Aanslagnummer of WOZ-objectnummer is verplicht' : 'Aanslagnummer of objectnummer is verplicht';
        }

        return true;
    }

    private createdSingleDossier(): void {
        if (this.redirectBack) {
            this.$snackbar.success('Uw bezwaarschrift is succesvol verstuurd');
            this.refreshForm();
        } else {
            this.$vueDialogs.open(CreateDossierRedirectDialog, {
                dialogSettings: {
                    maxWidth: '728px',
                },
            }).result.observe(redirect => redirect ? this.redirectToOverview() : this.refreshForm());
        }
    }

    private createdBulkDossier(bulkBatch: BulkBatch): void {
        this.$vueDialogs.open(ConfirmDialog, {
            propsData: {
                title: 'De bulkupload is geslaagd',
                message: 'Uw bulkupload is succesvol verwerkt. U kunt nu de gegevens van de upload bekijken en eventuele fouten corrigeren.',
                confirmButtonText: 'Naar overzicht',
                type: 'info'
            },
            dialogSettings: {
                maxWidth: '728px',
            },
        }).onConfirm.observe(() => this.redirectToBulkUploadOverview(bulkBatch.id.toString()));
    }

    private refreshForm(): void {
        this.dossierRequest = Object.assign({}, {
            taxOfficeCode: this.dossierRequest.taxOfficeCode,
            claims: [
                {
                    reason: ""
                }
            ],
            files: []
        });

        this.form.resetValidation();

        this.$vuetify.goTo(0);
    }
    private redirectToOverview(): void {
        this.$router.push({
            name: "dossiers-overview",
        });
    }

    private redirectToBulkUploadOverview(bulkUploadId: string): void {
        this.$router.push({
            name: 'bulk-upload-details',
            params: {
                bulkUploadId: bulkUploadId.toString(),
            },
        });
    }

    private async getObjectionReasonsForTaxOffice(): Promise<void> {
        try {
            const taxOffice = this.taxOffices.find(taxOffice => taxOffice.code === this.dossierRequest.taxOfficeCode) as TaxOffice;

            this.objectionReasons = await this.taxOfficeService.getObjectionReasons(taxOffice.id);

            if (!this.objectionReasons.find(reason => reason.code === this.dossierRequest.claims[0].reason)) {
                this.dossierRequest.claims[0].reason = '';
            }
        } catch (e) {
            this.$snackbar.danger("Het ophalen van de beschikbare bezwaarredenen is mislukt");
        }
    }
}
