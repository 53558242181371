import Vue from "vue";
import Component from "vue-class-component";
import html from "./app.component.html";
import {Inject} from "vue-property-decorator";
import {SecurityService} from "@bakerware/vue-authentication-module";
import {isTestEnvironment} from "@ncnp-mono/utils";

@Component({
    template: html,
})
export class AppComponent extends Vue {
    @Inject()
    public securityService!: SecurityService;

    public appInitialized = false;

    async beforeMount() {
        await this.$auth.checkToken(this.securityService);

        this.appInitialized = true
    }

    get environmentSpecificBackground(): object {
        return {
            'background-prod': !isTestEnvironment(),
            'background-test': isTestEnvironment()
        };
    }
}

