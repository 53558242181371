import {IsDialogMixin, Observable} from "@bakerware/vue-dialogs";
import Component from "vue-class-component";
import html from './create-dossier-redirect.dialog.html';
@Component({
    template: html,
})
export class CreateDossierRedirectDialog extends IsDialogMixin {
    public result: Observable<boolean> = new Observable<boolean>();

    public confirmRedirect(redirect: boolean): void {
        this.result.emit(redirect);
        this.activeDialog.close();
    }
}
