import { alphanumeric, numeric, postcode, alpha} from "@bakerware/vue-validation-rules";

export const BulkUploadRules = {
    subjectnummer: [
        numeric
    ],
    postcode: [
        postcode
    ],
    huisnummer: [
        numeric
    ],
    huisletter: [
        alpha
    ],
    adrestoevoeging: [
        alphanumeric
    ],
    vorderingsnummer: [
        numeric
    ],
    wozobjectnummer: [
        numeric
    ]
};
