import { RouteConfigSingleView } from "vue-router/types/router";
import {DossiersOverviewComponent} from "./pages/overview/dossiers-overview.component";
import {DossierDetailsComponent} from "./pages/details/dossier-details.component";
import {CreateDossierComponent} from "./pages/create/create-dossier.component";
import {DossierRouterViewComponent} from "./pages/router-view/dossier-router-view.component";
import { BulkUploadDetailsComponent } from "./pages/bulk-upload-details/bulk-upload-details-component";

export const DossierRoutingModule: RouteConfigSingleView[] = [
    {
        path: 'dossiers',
        name: 'dossiers-container',
        component: DossierRouterViewComponent,
        meta: {
            requiresAuth: true,
            breadcrumb: {
                title: 'Bezwaren',
                to: 'dossiers-overview'
            }
        },
        children: [
            {
                path: 'bulkUpload/:bulkUploadId',
                name: 'bulk-upload-details',
                component: BulkUploadDetailsComponent,
                meta: {
                    requiresAuth: true,
                    breadcrumb: {
                        title: 'Bulk Upload Overzicht',
                    },
                },
            },
            {
                path: 'overzicht',
                name: 'dossiers-overview',
                component: DossiersOverviewComponent,
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: 'dossier',
                name: 'dossier-create',
                component: CreateDossierComponent,
                meta: {
                    requiresAuth: true,
                    breadcrumb: {
                        title: 'Bezwaar aanmaken',
                    },
                },
            },
            {
                path: ':dossierId',
                name: 'dossier-details',
                component: DossierDetailsComponent,
                props: true,
                meta: {
                    requiresAuth: true,
                    breadcrumb: {
                        title: 'Bezwaar details',
                    },
                },
            },
        ]
    }
];
