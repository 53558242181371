import { ErrorBag, VForm } from "@bakerware/vue-utils";
import { ConfirmDialog } from "@ncnp-mono/components";
import { BulkBatch, BulkBatchRow } from "@ncnp-mono/generics";
import { BulkBatchRowService, BulkBatchService, TaxOfficeService } from "@ncnp-mono/utils";
import Vue from "vue";
import Component from "vue-class-component";
import { Inject, Prop, Ref } from "vue-property-decorator";
import { DataTableHeader } from "vuetify";
import { BulkUploadHeaders } from "../../config/bulk-upload-headers";
import { BulkUploadRules } from "../../config/bulk-upload-rules";
import { UploadFileDialog } from "../../dialogs/upload-file/upload-file.dialog";
import html from "../bulk-upload-details/bulk-upload-details-component.html";
import { BulkUploadType } from "../../config/bulk-upload-type";
import {BulkBatchStatus} from "../../config";

@Component({
    components: {

    },
    template: html,
})
export class BulkUploadDetailsComponent extends Vue {
    @Inject()
    bulkBatchService!: BulkBatchService;

    @Inject()
    bulkBatchRowService!: BulkBatchRowService;

    @Inject()
    taxOfficeService!: TaxOfficeService;

    @Ref('form')
    form!: VForm;

    @Prop()
    public bulkUploadId!: string;

    public headers: DataTableHeader[] = BulkUploadHeaders;

    public bulkBatch: BulkBatch | null = null;
    public bulkBatchRows: BulkBatchRow[] = [];

    public rules = BulkUploadRules;

    public loading = {
        bulkBatch: true,
        bulkBatchRows: true,
        submit: false,
        action: false
    }

    public isProcessed = false;
    public rowsChanged = false;

    public reasons: { text: string; value: string; }[] = [];

    public sorts = [
        { text: 'Foto', value: 'foto' },
        { text: 'Hoorverslag', value: 'hoorverslag' },
        { text: 'Machtiging', value: 'machtiging' },
        { text: 'Taxatieverslag', value: 'taxatieverslag' },
        { text: 'Overig', value: 'overig' },
    ];

    private getReasons(bulkBatchRow: BulkBatchRow) {
        if (this.rowFieldHasErrors('type', bulkBatchRow) || bulkBatchRow.type === BulkUploadType.Revoked) {
            return [];
        }

        return this.reasons;
    }

    private getSorts(bulkBatchRow: BulkBatchRow) {
        if (this.rowFieldHasErrors('type', bulkBatchRow) || bulkBatchRow.type === BulkUploadType.Revoked) {
            return [];
        }

        return this.sorts;
    }

    public get canProcess(): boolean {
        return this.allErrorsResolved
            && this.bulkBatchRows.length > 0
            && !this.rowsChanged
            && this.form?.validate();
    }

    public get allErrorsResolved(): boolean {
        return !this.bulkBatchRows.some(obj => {
            return Object.keys(obj.errors).some(
                (key) => obj.errors[key].length > 0
            );
        })
    }

    async beforeMount(): Promise<void>  {
        try {
            this.bulkBatch =  await this.bulkBatchService.getBulkBatch(this.$route.params.bulkUploadId)
            this.isProcessed = this.bulkBatch.status !== BulkBatchStatus.CREATED;

            if (!this.isProcessed) {
                this.bulkBatchRows = await this.getBulkBatchRows(this.$route.params.bulkUploadId)
                await this.getReasonsForTaxOffice();
            }
        } catch (e) {
            this.$snackbar.danger('Er is een fout opgetreden bij het ophalen van de bulk batch')
        }

        this.loading.bulkBatch = false;
    }

    public async submitBulkUpload(): Promise<void>  {
        this.loading.submit = true;

        if (this.bulkBatch === null) {
            return this.$snackbar.danger('Bulk batch niet beschikbaar')
        }

        try {
            this.bulkBatchRows = await this.bulkBatchRowService.bulkUpdateBulkBatchRows(
                this.$route.params.bulkUploadId,
                {
                    bulkBatchRows: this.bulkBatchRows
                }
            );

            this.$snackbar.success('De bulk upload is succesvol opgeslagen');
            this.rowsChanged = false;
        } catch (e) {
            this.$snackbar.danger('Fout bij het opslaan van de bulk upload');
        }

        this.loading.submit = false;

    }

    public async removeBulkBatch(): Promise<void>  {
        this.loading.action = true;

        if (this.bulkBatch === null) {
            return this.$snackbar.danger('Bulk batch niet beschikbaar')
        }

        try {
            await this.bulkBatchService.deleteBulkBatch(this.bulkBatch.id);
            await this.$router.push({
                name: 'dossier-create',
            });
        } catch (e) {
            this.$snackbar.danger('Fout bij het opslaan van de bulk upload');
        }

        this.loading.action = false;
    }

    public async removeBulkBatchRow(bulkBatchRow: BulkBatchRow): Promise<void>  {
        this.loading.action = true;

        try {
            await this.bulkBatchService.deleteBulkBatchRow(bulkBatchRow.id);

            this.bulkBatchRows = this.bulkBatchRows.filter(
                row => row.id !== bulkBatchRow.id
            )

            this.$snackbar.success('de regel is succesvol verwijderd');
        } catch (e) {
            this.$snackbar.danger('Het verwijderen van de regel is mislukt');
        }

        this.loading.action = false;
    }

    public async processBulkUpload(): Promise<void>  {
        this.loading.action = true;

        await this.submitBulkUpload()

        if (!this.canProcess) {
            return this.$snackbar.danger('Er zijn fouten gevonden in uw bulk upload, los deze op en probeer opnieuw');
        }

        if (this.bulkBatch === null) {
            return this.$snackbar.danger('Bulk batch niet beschikbaar')
        }

        try {
            await this.bulkBatchService.processBulkBatch(this.bulkBatch.id);
            this.isProcessed = true;
        } catch (e) {
            this.isProcessed = false;
            this.$snackbar.danger('Er is een fout opgetreden tijdens het verwerken van de bulk upload');
        }

        this.loading.action = false;
    }

    private openFileDialog(bulkBatchRow: BulkBatchRow): void {
        const uploadFileDialog = this.$vueDialogs.open(UploadFileDialog, {
            propsData: {
                bulkBatchRow: bulkBatchRow
            },
            dialogSettings: {
                maxWidth: '1000px',
                scrollable: true
            },
        });

        uploadFileDialog.bulkUploadRowChanged.observe(async () => {
            this.bulkBatchRows = await this.getBulkBatchRows(this.$route.params.bulkUploadId);
            const row = this.bulkBatchRows.find((bulkBatchRowAlt) => {
                return bulkBatchRowAlt.id === bulkBatchRow.id;
            });

            if (row === undefined) {
                this.$snackbar.danger('Er is een fout opgetreden. Probeer de pagina opnieuw te laden.');
                return;
            }

            uploadFileDialog.bulkBatchRow = row;
        });
    }

    private getFilesButtonState(bulkBatchRow: BulkBatchRow): 'primary' | 'warning' {
        return bulkBatchRow.bulkBatchRowsFiles.length || this.isRevoked(bulkBatchRow) ? 'primary' : 'warning'
    }

    private hasDossierAndDossierType(bulkBatchRow: BulkBatchRow) {
        return bulkBatchRow.dossier && bulkBatchRow.type.toLowerCase() === BulkUploadType.New.toLowerCase();
    }

    private rowFieldHasErrors(field: string, bulkBatchRow: BulkBatchRow): boolean {
        const errorBag = new ErrorBag(bulkBatchRow.errors);

        return errorBag.has(field) && errorBag.get(field).length > 0;
    }

    private rowHasFileErrors(bulkBatchRow: BulkBatchRow): boolean {
        const errorBag = new ErrorBag(bulkBatchRow.errors);

        return errorBag.get('fileErrors').length !== 0 ||
            errorBag.get('missingFiles').length !== 0;
    }

    private getFileErrors(bulkBatchRow: BulkBatchRow): string[] {
        const errorBag = new ErrorBag(bulkBatchRow.errors);

        const collectedErrors: string[] = [];

        errorBag.get('fileErrors').forEach((fileError: string) =>
            collectedErrors.push(fileError)
        );

        errorBag.get('missingFiles').forEach((missingFile: string) =>
            collectedErrors.push(missingFile)
        );

        return collectedErrors;
    }

    private getRowErrorsForField(field: string, bulkBatchRow: BulkBatchRow): string[] {
        return new ErrorBag(bulkBatchRow.errors).get(field);
    }

    private rowFieldChanged(field: string, bulkBatchRow: BulkBatchRow): void {
        if (!this.bulkBatch) {
           return
        }

        this.rowsChanged = true;

        this.bulkBatchRows = this.bulkBatchRows.map((_bulkBatchRow) => {
            if (_bulkBatchRow.id === bulkBatchRow.id) {
                _bulkBatchRow.errors[field] = [];
            }

            return _bulkBatchRow
        });
    }

    private isRevoked(bulkBatchRow: BulkBatchRow): boolean {
        return bulkBatchRow.type.toLowerCase() === BulkUploadType.Revoked.toLowerCase();
    }

    private isSupplement(bulkBatchRow: BulkBatchRow): boolean {
        return bulkBatchRow.type.toLowerCase() === BulkUploadType.Supplement.toLowerCase();
    }

    private getUploadTypes(bulkBatchRow: BulkBatchRow) {
        if (bulkBatchRow.dossier) {
            return [
                { text: 'Nieuw', value: BulkUploadType.New },
                { text: 'Aanvulling', value: BulkUploadType.Supplement },
                { text: 'Intrekking', value: BulkUploadType.Revoked },
            ];
        }

        return [
            { text: 'Nieuw', value: BulkUploadType.New },
        ];
    }

    private processBulkUploadAlert(): void {
        this.$vueDialogs.open(ConfirmDialog, {
            propsData: {
                message: 'U staat op het punt om uw bulk upload in te dienen',
                confirmButtonText: 'Indienen',
                type: 'info',
            },
            dialogSettings: {
                maxWidth: '728px',
            },
        }).onConfirm.observe(this.processBulkUpload);
    }

    private deleteBulkBatch(): void {
        this.$vueDialogs.open(ConfirmDialog, {
            propsData: {
                message: 'U staat op het punt om uw bulk upload te verwijderen',
                type: 'error',
            },
            dialogSettings: {
                maxWidth: '728px',
            },
        }).onConfirm.observe(this.removeBulkBatch);
    }

    private deleteBulkBatchRow(bulkBatchRow: BulkBatchRow): void {
        this.$vueDialogs.open(ConfirmDialog, {
            propsData: {
                message: 'U staat op het punt om een regel van uw bulk upload te verwijderen',
            },
            dialogSettings: {
                maxWidth: '728px',
            },
        }).onConfirm.observe(() => this.removeBulkBatchRow(bulkBatchRow))
    }

    private cancelBulkBatch(): void {
        this.$vueDialogs.open(ConfirmDialog, {
            propsData: {
                message: 'Eventuele aanpassingen worden hiermee niet opgeslagen, weet u het zeker?',
                confirmButtonText: 'Bevestigen',
            },
            dialogSettings: {
                maxWidth: '728px',
            },
        }).onConfirm.observe(() =>
            this.$router.push({
                name: 'dossier-create'
            })
        );
    }

    /**
     * @todo implement real i18n
     */
    private i18nFilesText(bulkBatchRow: BulkBatchRow): string {
        const amountOfFiles = bulkBatchRow.bulkBatchRowsFiles.length;

        const quantifier = amountOfFiles ? amountOfFiles : 'Geen';
        const adjective = amountOfFiles !== 1 ? 'en' : ''

        return `${quantifier} bestand${adjective}`
    }

    public getTypeErrorMessage(type: string) {
        if (type === 'NO_DOSSIER_FOUND') {
            return 'Er is geen dossier gevonden. Gelieve het type te wijzigen of deze regel te verwijderen.';
        }

        return 'Er is een fout opgetreden.';
    }

    private async getReasonsForTaxOffice(): Promise<void> {
        if (this.bulkBatch === null) {
            return this.$snackbar.danger('Bulk batch niet beschikbaar')
        }

        try {
            const possibleReasons = [
                { code: 'WOZ_TE_HOOG', option: { text: 'Hoog', value: 'hoog' } },
                { code: 'WOZ_TE_LAAG', option: { text: 'Laag', value: 'laag' } },
                { code: 'ONEENS_NAHEF_PARKEER', option: { text: 'Parkeerboete', value: 'park' } },
                { code: 'OVERIG', option: { text: 'Overig', value: 'overig' } },
            ];

            const objectionReasons = await this.taxOfficeService.getObjectionReasons(
                this.bulkBatch.taxOffice.id
            );

            this.reasons = possibleReasons.filter(
                possibleReasons => objectionReasons.some(
                    item => item.code === possibleReasons.code
                )
            ).map(possibleReasons => possibleReasons.option)

        } catch (e) {
            this.$snackbar.danger("Het ophalen van de beschikbare bezwaarredenen is mislukt");
        }
    }

    private async getBulkBatchRows(bulkBatchId: string): Promise<BulkBatchRow[]> {
        try {
            const rows = this.bulkBatchRowService.getBulkBatchRows(
                bulkBatchId
            );
            this.loading.bulkBatchRows = false;

            return rows;
        } catch (e) {
            this.$snackbar.danger('Er is een fout opgetreden bij het ophalen van bulk upload regels')
        }

        this.loading.bulkBatchRows = false;
        return [];
    }
}
