import {
    GeneralUserInformationComponent,
    UserSecuritySettingsComponent,
    UserVisualSettingsComponent
} from "@ncnp-mono/components";
import Vue from "vue";
import Component from "vue-class-component";
import html from './user-profile.component.html';
import { Inject } from "vue-property-decorator";
import { JwtBundleSettings, SecurityService, TfaService, ConfirmedIdentity } from "@bakerware/vue-authentication-module";

@Component({
    template: html,
    components: {
        GeneralUserInformationComponent,
        UserSecuritySettingsComponent,
        UserVisualSettingsComponent
    }
})
export class UserProfileComponent extends Vue {
    @Inject()
    securityService!: SecurityService;

    @Inject()
    tfaService!: TfaService;

    public jwtBundleSettings?: JwtBundleSettings | null = null;

    mounted() {
        this.getJwtBundleSettings();
    }

    private async getJwtBundleSettings(): Promise<void> {
        this.jwtBundleSettings = await this.securityService.getJwtBundleSettings();
    }

    public resetTfa(confirmedIdentity: ConfirmedIdentity) {
        this.tfaService.disableTfa(confirmedIdentity).then((response) => {
            if (response.success) {
                if (this.$auth.user?.userSettings?.security) {
                    this.$auth.user.userSettings.security.useTwoFactorAuthentication = false;
                }

                this.$auth.checkTfaRequired(this.securityService, this.$vueDialogs).then(() => {
                    this.$snackbar.success('Tweefactorauthenticatie succesvol gereset.');
                });
            }
        }).catch(() => {
            this.$snackbar.danger('Er ging iets fout bij het uitschakelen van de tweefactorauthenticatie.');
        });
    }
}
