import {RouteConfigSingleView} from "vue-router/types/router";
import {BulkDownloadTaxReportsComponent} from "./pages/bulk-download-tax-reports/bulk-download-tax-reports.component";

export const TaxReportsRoutingModule: RouteConfigSingleView[] = [
    {
        path: 'bulk-tax-reports/:fileId',
        name: 'bulk-tax-reports',
        component: BulkDownloadTaxReportsComponent,
        meta: {
            requiresAuth: true,
            breadcrumb: {
                title: 'Download taxatieverslagen'
            }
        }
    },
];
