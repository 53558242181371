import { AllowedMimeType, FileTypesEnum } from "@ncnp-mono/generics";

export const CreateBulkUploadMimeTypes: AllowedMimeType[] = [
    {
        type: FileTypesEnum.ZIP,
        extension: 'zip',
        count: 1
    },
    {
        type: FileTypesEnum.XZIP,
        extension: 'zip',
        count: 1
    },
    {
        type: FileTypesEnum.XZIPCOMPRESSED,
        extension: 'zip',
        count: 1
    },
    {
        type: FileTypesEnum.XCOMPRESS,
        extension: 'zip',
        count: 1
    },
    {
        type: FileTypesEnum.XCOMPRESSED,
        extension: 'zip',
        count: 1
    },
    {
        type: FileTypesEnum.MULTIXZIP,
        extension: 'zip',
        count: 1
    },
];
