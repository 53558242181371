import { IsDialogMixin } from "@bakerware/vue-dialogs";
import Component from "vue-class-component";
import html from './create-bulk.dialog.html';
import { Observable } from "@bakerware/vue-utils";
import { Inject, Prop } from "vue-property-decorator";
import { getImage, BulkBatchService } from "@ncnp-mono/utils";
import { type BulkUploadRequest, type TaxOffice, type BulkBatch } from "@ncnp-mono/generics";
import { UnprocessableEntityException } from "@bakerware/http";

@Component({
    template: html,
    methods: {
        getImage
    }
})
export class CreateBulkDialog extends IsDialogMixin {
    @Inject()
    bulkBatchService!: BulkBatchService;

    @Prop()
    taxOffice!: TaxOffice;

    @Prop()
    info!: { type: string, file: File };

    @Prop()
    BulkUploadRequest!: BulkUploadRequest;

    public loading = false;

    public result = new Observable<BulkBatch>();

    public async confirm(): Promise<void> {
        try {
            this.loading = true;

            const bulkBatch = await this.bulkBatchService.createBulkBatch(this.BulkUploadRequest);
            this.activeDialog.close();
            this.result.emit(bulkBatch);
        } catch (e) {
            if (e instanceof UnprocessableEntityException) {
                this.$snackbar.danger('Geen CSV bestand gevonden in het opgegeven ZIP bestand');
            } else {
                this.$snackbar.danger('Fout bij maken van de bulk upload');
            }
        } finally {
            this.loading = false;
        }
    }
}
