import { IsDialogMixin } from '@bakerware/vue-dialogs';
import Component from 'vue-class-component';
import html from './revoke-claim.dialog.html';
import { Observable, type VForm } from '@bakerware/vue-utils';
import {Inject, Prop, Ref} from 'vue-property-decorator';
import {hasNoScriptTags} from '@bakerware/vue-validation-rules';
import {ClaimService} from "@ncnp-mono/utils";
import { type Claim, RevokeClaimRequest, ClaimStateEnum, StateClaimRequest } from "@ncnp-mono/generics";

@Component({
    template: html,
})
export class RevokeClaimDialog extends IsDialogMixin {

    @Prop()
    claim!: Claim;

    @Inject()
    claimService!: ClaimService;

    @Ref('form')
    form!: VForm;

    public loading = false;
    public result: Observable<boolean> = new Observable<boolean>();

    public rules = {
        reasonRevoked: [hasNoScriptTags],
    };

    public stateClaimRequest: StateClaimRequest = {
        state: ClaimStateEnum.REVOKECLAIM
    }

    public revokeClaimRequest: RevokeClaimRequest = {
        ...this.stateClaimRequest,
    };

    public async confirm(): Promise<void> {
        if (!this.form.validate()) {
            return;
        }

        let snackbarText = '';

        if (this.claim.objectNumber) {
            snackbarText = ` met objectnummer ${this.claim.objectNumber}`;
        } else if (this.claim.dossier?.claimNumber) {
            snackbarText = ` met aanslagnummer ${this.claim.dossier?.claimNumber}`;
        }

        try {
            this.loading = true;

            await this.claimService.updateClaim(this.claim.id, this.revokeClaimRequest);

            this.$snackbar.success(`U heeft het bezwaar voor het object${snackbarText} ingetrokken`);

            this.result.emit(true);

            this.close();
        } catch (e) {
            this.$snackbar.danger(`Er is een fout opgetreden bij het intrekken van het bezwaar voor het object${snackbarText}`);
        } finally {
            this.loading = false;
        }
    }

    public close(): void {
        this.activeDialog.close();
    }
}
