import { RouteConfig } from 'vue-router';
import { BaseComponent } from '../../components/base/base.component';
import { AuthRoutingModule } from "@bakerware/vue-authentication-module";
import {
    DashboardRoutingModule,
    DossierRoutingModule,
    OrganisationRoutingModule,
    UserProfileRoutingModule
} from "../../../modules";
import {TaxReportsRoutingModule} from "../../../modules/tax-reports/tax-reports-routing.module";
import { ContactInfoRoutingModule } from '../../../modules/contact-info/contact-info-routing.module';

export const routes: RouteConfig[] = [
    ...AuthRoutingModule,
    ...ContactInfoRoutingModule,
    {
        path: '/',
        name: 'core',
        component: BaseComponent,
        meta: {
            requiresAuth: true,
            breadcrumb: {
                title: 'Dashboard',
                to: 'dashboard'
            },
        },
        children: [
            ...UserProfileRoutingModule,
            ...DashboardRoutingModule,
            ...DossierRoutingModule,
            ...OrganisationRoutingModule,
            ...TaxReportsRoutingModule
        ],
    },
];
