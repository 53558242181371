import { IsDialogMixin } from '@bakerware/vue-dialogs';
import Component from 'vue-class-component';
import html from './create-tax-reports.dialog.html';
import { Observable, type VForm } from '@bakerware/vue-utils';
import { Inject, Prop, Ref } from 'vue-property-decorator';
import {BulkTaxReportsService, OrganisationDossierService} from '@ncnp-mono/utils';
import {BulkTaxReportsRequest, TaxOffice, BulkTaxReportsResponse} from '@ncnp-mono/generics';
import { required } from '@bakerware/vue-validation-rules';

@Component({
    template: html,
})
export class CreateTaxReportsDialog extends IsDialogMixin {

    @Prop({
        default: [],
    })
    taxOffices!: TaxOffice[];

    @Inject()
    bulkTaxReportsService!: BulkTaxReportsService;

    @Inject()
    organisationDossierService!: OrganisationDossierService;

    @Ref('form')
    form!: VForm;

    public taxOfficeId: number | null = null;
    public taxYears: number[] = [];
    public downloadAll = false;

    public loading = false;
    public result = new Observable<string>();

    public bulkTaxReportsResponse: BulkTaxReportsResponse | null = null;
    public bulkTaxReportsRequest: BulkTaxReportsRequest = {};

    public rules = {
        taxOfficeId: [required],
        taxYear: [required],
    };

    public get preparedBulkTaxReportsRequest(): BulkTaxReportsRequest {
        this.bulkTaxReportsRequest.nonDownloadedOnly = !this.downloadAll;

        return this.bulkTaxReportsRequest;
    }

    public created(): void {
        this.getAvailableTaxYears();
    }

    public async confirm(): Promise<void> {
        try {
            this.loading = true;

            this.bulkTaxReportsResponse = await this.bulkTaxReportsService.createAsynchronousTaxReports(this.preparedBulkTaxReportsRequest);
        } catch (e) {
            this.$snackbar.danger('Er is een fout opgetreden bij het downloaden van de taxatieverslagen');
        } finally {
            this.loading = false;
        }
    }

    public close(): void {
        this.activeDialog.close();
    }

    public getAvailableTaxYears(): void {
        this.organisationDossierService.getAvailableTaxYears()
            .then(taxYears => this.taxYears = taxYears)
            .catch(() => this.$snackbar.danger('Er is iets misgegaan bij het ophalen van de beschikbare taxatiejaren'));
    }
}
