import { NotFoundException } from '@bakerware/http';
import { IsDialogMixin, VForm } from '@bakerware/vue-dialogs';
import { ErrorBag, Observable } from '@bakerware/vue-utils';
import { mimetypes } from '@bakerware/vue-validation-rules';
import { FileUploadComponent } from '@ncnp-mono/components';
import { BulkBatchRow, BulkBatchRowFile, FileTypesEnum } from '@ncnp-mono/generics';
import { BulkBatchService, getImage } from '@ncnp-mono/utils';
import Component from 'vue-class-component';
import { Inject, Prop, Ref, Watch } from 'vue-property-decorator';
import html from './upload-file.dialog.html';

@Component({
    components: {
        FileUploadComponent
    },
    template: html,
    methods: {
        getImage,
    },
})
export class UploadFileDialog extends IsDialogMixin {
    @Inject()
    bulkBatchService!: BulkBatchService;

    @Prop()
    bulkBatchRow!: BulkBatchRow;

    private errorBag: ErrorBag = new ErrorBag({});

    @Watch('bulkBatchRow', {immediate: true})
    private watchBulkBatchRow() {
        this.errorBag = new ErrorBag(this.bulkBatchRow.errors);
    }

    @Ref('file-upload')
    public fileUploadForm!: VForm;
    public fileUploadFormValid = false;

    public bulkUploadRowChanged: Observable<boolean> = new Observable<boolean>();

    public fileUploadRules = [
        (v: never) =>
            mimetypes(v, [
                FileTypesEnum.JPEG,
                FileTypesEnum.PNG,
                FileTypesEnum.TXT,
                FileTypesEnum.PDF,
                FileTypesEnum.DOC,
                FileTypesEnum.DOCX,
                FileTypesEnum.XLS,
                FileTypesEnum.XLSX,
            ]),
    ]

    public file: File | null = null;

    public result: Observable<boolean> = new Observable<boolean>();

    public async uploadFile(): Promise<void>  {
        if (this.file === null) {
            return;
        }

        if (this.bulkBatchRow.bulkBatchRowsFiles.length >= 6) {
            this.$snackbar.danger('Upload limit van 6 bestanden is bereikt');
            return;
        }

        const formData = new FormData();
        formData.append("file", this.file);

        try {
            const bulkBatchRowFile = await this.bulkBatchService.addBulkBatchRowFile(this.bulkBatchRow.id , formData);
            this.bulkBatchRow.bulkBatchRowsFiles.push(bulkBatchRowFile);
        } catch {
            this.$snackbar.danger('Het uploaden van het bestand is mislukt');
        }

        this.file = null;
    }

    public async deleteFile(bulkBatchRowFile: BulkBatchRowFile): Promise<void> {
        try {
            await this.bulkBatchService.deleteBulkBatchRowFile(bulkBatchRowFile.id);
            this.bulkBatchRow.bulkBatchRowsFiles = this.bulkBatchRow.bulkBatchRowsFiles.filter(row => row.id !== bulkBatchRowFile.id)
        } catch {
            this.$snackbar.danger('Het verwijderen van het bestand is mislukt');
        }
    }

    private async downloadBulkBatchFile(bulkBatchRowFile: BulkBatchRowFile): Promise<void> {
        try {
            await this.bulkBatchService.downloadBulkBatchFile(bulkBatchRowFile);
        } catch (e) {
            if (e instanceof NotFoundException) {
                this.$snackbar.danger('Het bestand is niet gevonden op de server');
            } else {
                this.$snackbar.danger('Er is een fout opgetreden bij het downloaden van het document');
            }
        }
    }

    private fileHasErrors(filename: string): boolean {
        const errors = this.errorBag.get('fileErrors');

        const found = errors.find((error: string) => {
            return error.split(" bestandsformaat is niet toegestaan")[0] === filename
        });

        return found !== undefined;
    }

    private getFileErrors(filename: string): string|undefined {
        const errors = this.errorBag.get('fileErrors');

        const found = errors.find((error: string) => {
            return error.split(" bestandsformaat is niet toegestaan")[0] === filename
        });

        return found ?? 'Het bestand is niet toegestaan';
    }

    private hasMissingFiles(): boolean {
        return this.errorBag.get('missingFiles').length > 0;
    }

    private get missingFiles() {
        return this.errorBag.get('missingFiles');
    }

    private async deleteMissingFile(filename: string) {
        try {
            await this.bulkBatchService.removeMissingFile(this.bulkBatchRow.id, {filename: filename});
            this.bulkUploadRowChanged.emit(true)
        } catch {
            this.$snackbar.danger('Het verwijderen van het missende bestand is mislukt');
        }
    }

    private hasNoFiles(bulkBatchRow: BulkBatchRow): boolean {
        return bulkBatchRow.bulkBatchRowsFiles.length === 0
    }
}
